import { Router } from "@reach/router";
import Home from "./views/home/Index";
import Crowd from "./views/pages/crowd-campaign";
import Contact from "./views/pages/contact";
import Privacy from "./views/legal/privacy-policy";

import P500 from "./views/_common/p500";
import P404 from "./views/_common/p404";

function AppNav(props) {
  return (
    <Router>
      <Home path={"/"} />
      <Contact path={"/contact"} />
      <Crowd path={"/crowd"} />
      <Privacy path={"legal/privacy"} />

      <P500 path="/505" />
      <P404 default />
    </Router>
  );
}
export default AppNav;
