import AppNav from './router'
/**
 */
function App() {
    return (
        <AppNav />
    )
}

/**
 */
export default App


