import React from "react";
import "assets/css/style.css";
// reactstrap components
// import {
// } from "reactstrap";

// core components
import IndexNavbar from "components/Navbars/IndexNavbar.js";
import DarkFooter from "components/Footers/DarkFooter.js";

// sections for this page
import IndexHeader from "./header.js";
import OnePlace from "./one-place.js";
import Fundraise from "./fundraise.js";
import OldWay from "./old-way";
import MagnoliaWay from "./magnolia-way";
import Plan from "./plan";
import Security from "./security";
import WhyUs from "./why-us";

function IndexHome() {
  React.useEffect(() => {
    document.body.classList.add("index-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("index-page");
      document.body.classList.remove("sidebar-collapse");
    };
  });
  return (
    <>
      <IndexNavbar />
      <div className="wrapper">
        <IndexHeader />
        <div className="main">
          <OnePlace />
          <Fundraise />
          <OldWay />
          <MagnoliaWay />
          {/* <Plan /> */}
          <Security />
          <WhyUs />
        </div>
        <DarkFooter />
      </div>
    </>
  );
}

export default IndexHome;
