/*eslint-disable*/
import React from "react";

// reactstrap components
import { Container, Row, Col } from "reactstrap";
// core components

function WhyUs() {
  return (
    <>
      <div className="section text-center">
        <Container>
          <div className="content-center brand">
            <h2 className="text-info py-3">Why Us?</h2>
            <Row>
              <Col>
                <div className="">
                  <img
                    alt="..."
                    className="why-logo pb-4"
                    src={require("assets/img/value.png").default}
                  ></img>
                  <h3 className="text-info">Unmatched Value</h3>
                  <p>
                    You simply won’t find another solution that can match our
                    value proposition. You get a one-stop shop for all of your
                    fundraising needs, unlimited users, low processing rates for
                    credit cards, and affordable monthly plans.
                  </p>
                </div>
              </Col>
              <Col>
                <div>
                  <img
                    alt="..."
                    className="why-logo pb-4"
                    src={require("assets/img/time.png").default}
                  ></img>
                  <h3 className="text-info">Fast Deposits Are Standard</h3>
                  <p>
                    Accelerate your cash flow and gain access to your donations
                    in 24hrs via direct deposit. Unlike many other payment
                    processors, this is a standard feature, with no extra fees
                    required.
                  </p>
                </div>
              </Col>
              <Col>
                <div>
                  <img
                    alt="..."
                    className="why-logo pb-4"
                    src={require("assets/img/support.png").default}
                  ></img>
                  <h3 className="text-info">Enterprise-Level Support</h3>
                  <p>
                    We invest significant resources into our onboarding team to
                    coach customers through an effective 2 to 7 day launch
                    strategy along with ongoing support needed for great
                    results.
                  </p>
                </div>
              </Col>
            </Row>
            <Row style={{ marginTop: 50 }}>
              <Col>
                <h3 className="text-info">
                  You've got an ally. Let us help you deliver on your promise.
                </h3>
                <a href="" className="btn btn-info btn-long">
                  Request a Demo
                </a>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </>
  );
}

export default WhyUs;
