/*eslint-disable*/
import React from "react";

// reactstrap components
import { Container, Row, Col, Button } from "reactstrap";
// core components

function OldWay() {
  return (
    <>
      <div className="section justify-content-center" filter-color="white">
        <Container>
          <Row>
            <Col lg="6" sm="12" className="d-flex align-items-center">
              <div className="text-left">
                <h1 className="text-info">The Old Way.</h1>
                <p>
                  Managing multiple platforms creates not only financial costs,
                  but time related ones as well. Keeping each platform running
                  can require additional resource, cause donor resource
                  management nightmares, and is expensive to maintain in an age
                  where nonprofits are struggling to stay relevant and gain
                  traction.
                </p>
              </div>
            </Col>
            <Col lg="6" sm="12">
              <div className="brand">
                <img
                  alt="..."
                  className="img img-responsive"
                  src={require("assets/img/old.png").default}
                ></img>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default OldWay;
