/*eslint-disable*/
import React from "react";
// import { navigate } from "@reach/router";
// reactstrap components
import { Container, Row, Col, Button } from "reactstrap";
// core components

function IndexHeader() {
  return (
    <>
      <div className="page-header clear-filter" filter-color="white">
        <Container>
          <Row>
            <Col lg="6" sm="12">
              <div className="text-left">
                <h1 className="text-info">
                  Your all-in-one <br />
                  fundraising platform!
                </h1>
                <p>
                  A simpler way to grow, automate, and streamline your
                  fundraising and operations. What we build helps nonprofits run
                  faster, raise more and accomplish more with less - less time,
                  less staff, less cost.
                </p>
                <a href="/contact" className="btn btn-info btn-long">
                  Request Demo
                </a>
              </div>
            </Col>
            <Col lg="6" sm="12">
              <div className="brand">
                <img
                  alt="..."
                  className="img img-responsive"
                  src={require("assets/img/hero-img.png").default}
                ></img>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default IndexHeader;
