import React from "react";
import "./style.css";

// reactstrap components
import {
  Button,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
} from "reactstrap";

// core components
import IndexNavbar from "components/Navbars/IndexNavbar.js";
import DarkFooter from "components/Footers/DarkFooter.js";

function ContactPage() {
  const [firstFocus, setFirstFocus] = React.useState(false);
  const [lastFocus, setLastFocus] = React.useState(false);
  React.useEffect(() => {
    document.body.classList.add("landing-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("landing-page");
      document.body.classList.remove("sidebar-collapse");
    };
  }, []);
  return (
    <>
      <IndexNavbar />
      <div className="wrapper">
        <div className="section">
          <Container>
            <Row>
              <div className="col border-right">
                <p className="">#Contact</p>
                <h2 className="text-info">Get in touch with us</h2>
              </div>
              <div className="col mt-4 pl-5">
                <p className="description">
                  Let us know what you want to know. We want to help people
                  learn, grow and achieve more in life.
                </p>
              </div>
            </Row>
          </Container>
        </div>

        <div className="section mt-0 pt-0">
          <Container>
            <Row>
              <div className="col card-box">
                <i className="fab fa-twitter contact-icon"></i>
                <div className="contact-info">
                  <span className="contact-title">Visit Us:</span>
                  <span className="contact-description">
                    70-80 Upper St Norwich NR2 1LT
                  </span>
                </div>
              </div>
              <div className="col card-box">
                <i className="fas fa-envelope-open-text contact-icon"></i>
                <div className="contact-info">
                  <span className="contact-title">Email:</span>
                  <span className="contact-description">info@turitor.com</span>
                </div>
              </div>
              <div className="col card-box">
                <i className="fas fa-phone contact-icon"></i>
                <div className="contact-info">
                  <span className="contact-title">Phone:</span>
                  <span className="contact-description">+11 222 333 44</span>
                </div>
              </div>
            </Row>
          </Container>
        </div>

        <div className="section section-contact-us text-center">
          <Container>
            <h2 className="text-info">Want to work with us?</h2>
            <p className="description">Your project is very important to us.</p>
            <Row>
              <Col className="text-center ml-auto mr-auto" lg="6" md="8">
                <InputGroup
                  className={
                    "input-lg" + (firstFocus ? " input-group-focus" : "")
                  }
                >
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="now-ui-icons users_circle-08"></i>
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="First Name..."
                    type="text"
                    onFocus={() => setFirstFocus(true)}
                    onBlur={() => setFirstFocus(false)}
                  ></Input>
                </InputGroup>
                <InputGroup
                  className={
                    "input-lg" + (lastFocus ? " input-group-focus" : "")
                  }
                >
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="now-ui-icons ui-1_email-85"></i>
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="Email..."
                    type="text"
                    onFocus={() => setLastFocus(true)}
                    onBlur={() => setLastFocus(false)}
                  ></Input>
                </InputGroup>
                <div className="textarea-container">
                  <Input
                    cols="80"
                    name="name"
                    placeholder="Type a message..."
                    rows="4"
                    type="textarea"
                  ></Input>
                </div>
                <div className="send-button">
                  <Button
                    block
                    className="btn-round"
                    color="info"
                    href="#pablo"
                    onClick={(e) => e.preventDefault()}
                    size="lg"
                  >
                    Send Message
                  </Button>
                </div>
              </Col>
            </Row>
          </Container>
        </div>

        <DarkFooter />
      </div>
    </>
  );
}

export default ContactPage;
