/*eslint-disable*/
import React from "react";

// reactstrap components
import { Container, Row, Col, Button } from "reactstrap";
// core components

function Security() {
  return (
    <>
      <div
        className="section section-bg justify-content-center"
        filter-color="white"
      >
        <Container>
          <Row>
            <Col lg="6" sm="12" className="d-flex align-items-center">
              <div className="text-left">
                <h1 className="text-info">Serious about Security.</h1>
                <p>
                  We use Micharity to run Micharity, so we protect your data as
                  if it were our own. We work with customers every day to make
                  sure their data security requirements and expectations are met
                  or exceeded.
                </p>
              </div>
            </Col>
            <Col lg="6" sm="12">
              <div className="card" style={{ padding: 30 }}>
                <h3 className="text-info"> Security and Protection.</h3>
                <ul className="checkmark">
                  <li>
                    PCI Level 1 compliant independently tested and bank grade
                    payments infrastructure
                  </li>
                  <li>
                    State - of - the - art cryptographic algorithms during data
                    transmission and at rest
                  </li>
                  <li>
                    Credit card numbers are not stored but instead each credit
                    card is tokenized and the token is stored{" "}
                  </li>
                  <li>
                    Single - sign on via industry standard authentication
                    protocols
                  </li>
                  <li>
                    Hosted in a first - class data center monitored 24/7 with a
                    completely dedicated network encryption and firewall
                  </li>
                </ul>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default Security;
