/*eslint-disable*/
import React from "react";

// reactstrap components
import { Container, Row, Col, Button } from "reactstrap";
// core components

function Fundraise() {
  return (
    <>
      <div className="section section-bg justify-content-center">
        <Container>
          <Row>
            <Col lg="6" sm="12">
              <div className="brand">
                <img
                  alt="..."
                  className="img img-responsive img-raised"
                  src={require("assets/img/fund.png").default}
                ></img>
              </div>
            </Col>
            <Col lg="6" sm="12" className="d-flex align-items-center">
              <div className="text-left">
                <h1 className="text-info">Fundraise like a pro.</h1>
                <p>
                  Collect all of your KPIs and critical metrics into an artfully
                  designed and intuitive dashboard with information streams
                  updated in real-time.
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default Fundraise;
