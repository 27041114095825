/*eslint-disable*/
import React from "react";

// reactstrap components
import { Container, Row, Col } from "reactstrap";

function DarkFooter() {
  return (
    <footer className="footer section-bg">
      <Container className="footer-menu-main">
        <Row>
          <Col sm="3" md="3" lg="3">
            <img
              alt="..."
              className="logo"
              src={require("assets/img/mag-logo.png").default}
              width={100}
            ></img>
            <p className="mt-3 pl-0 pr-5" style={{ fontSize: 14 }}>
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry's standard dummy text
              ever since the 1500s,
            </p>
          </Col>
          <Col sm="3" md="3" lg="3">
            <h6>Support Us</h6>
            <ul className="footer-menu">
              <li>Suppourt Center</li>
              <li>Customer Support</li>
              <li>About Us</li>
              <li>Copyright</li>
              <li>Popular Campaign</li>
            </ul>
          </Col>
          <Col sm="3" md="3" lg="3">
            <h6>Our Information</h6>
            <ul className="footer-menu">
              <li>Return Policy</li>
              <li>Privacy Policy</li>
              <li>Terms & Conditions</li>
              <li>Site Map</li>
              <li>Sotre Hours</li>
            </ul>
          </Col>

          <Col sm="3" md="3" lg="3">
            <h6>Contact</h6>
            <ul className="footer-menu">
              <li>
                <span className="fa fa-map-marker pr-2"></span>
                London SW1A 1AA, United Kingdom
              </li>
              <li>
                <span class="fa fa-envelope pr-2"></span>
                info@magnolia.zone
              </li>
              <li>
                <span class="fa fa-phone pr-2"></span> +11 222 333 44
              </li>
            </ul>
          </Col>
        </Row>
      </Container>
      <Container className="copy-right">
        <Row>
          <Col sm="12" md="6">
            <h3 className="text-info">Magnolia</h3>
          </Col>
          <Col sm="12" md="6">
            {/* <ul className="footer-copyright">
              <li>Home</li>
              <li>Advertise </li>
              <li>Support</li>
              <li> Marketing</li>
              <li> FAQ</li>
            </ul> */}
            <p className="text-info text-right">
              {/* Developed by{" "} */}
              @Copyright 2022
              {/* <a href="#" className="text-primary">
                Megalogic & Technology
              </a> */}
            </p>
          </Col>
        </Row>
      </Container>
    </footer>
  );
}

export default DarkFooter;
