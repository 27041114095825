/*eslint-disable*/
import React from "react";

// reactstrap components
import { Container, Row, Col, Button } from "reactstrap";
// core components

function MagnoliaWay() {
  return (
    <>
      <div className="section section-bg justify-content-center">
        <Container>
          <Row>
            <Col lg="6" sm="12">
              <div className="brand">
                <img
                  alt="..."
                  className="img img-responsive"
                  src={require("assets/img/loremfund.png").default}
                ></img>
              </div>
            </Col>
            <Col lg="6" sm="12" className="d-flex align-items-center">
              <div className="text-left">
                <h1 className="text-info">The Magnolia way.</h1>
                <p>
                  Everything you need is under one roof and all of your data
                  rolls into one place. We’re ushering in a new age of
                  fundraising with powerful analytics and fully actionable,
                  analytics-driven interactions with data enrichment tools.
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default MagnoliaWay;
