import React from "react";

// reactstrap components
import { Container, Row } from "reactstrap";

// core components
import IndexNavbar from "components/Navbars/IndexNavbar.js";
import DarkFooter from "components/Footers/DarkFooter.js";

function PrivacyPage() {
  const [firstFocus, setFirstFocus] = React.useState(false);
  const [lastFocus, setLastFocus] = React.useState(false);
  React.useEffect(() => {
    document.body.classList.add("landing-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("landing-page");
      document.body.classList.remove("sidebar-collapse");
    };
  }, []);
  return (
    <>
      <IndexNavbar />
      <div className="wrapper">
        <div className="section">
          <Container>
            <Row>
              <h1 className="m-auto">MAGNOLIA PRIVACY POLICY</h1>

              <div className="mt-4">
                <h3>1. OVERVIEW</h3>
                <p>
                  Magnolia Limited and its subsidiaries and affiliates
                  (collectively Magnolia) offer a variety of products and
                  services that will be referred to in this policy as the
                  “Service” or “Services”. Because we would like to avoid
                  excessive formality and make this policy more readable, the
                  policy will often refer to Magnolia as “we” or “us” and to the
                  reader as “you”. When we say “device” we mean any device,
                  including desktop, laptop, PC or Mac computers, smartphones,
                  tablets or any other device that is used to access or interact
                  with the Service. When we say “Developer”, we mean a third
                  party that develops and/or distributes software or other app
                  made with and to serve Magnolia Services. By using the
                  Service, you agree to this Privacy Policy and that we may
                  transfer your data outside of the European Economic Area
                  (“EEA”).
                </p>
                <p>
                  This privacy policy governs our information practices for the
                  Service, which includes any websites, where a link to this
                  policy is provided. It describes how we collect, use, share,
                  and protect information from Developers, players, ad
                  recipients, or people browsing or using our sites
                  (collectively “Users”), when Users access or interact with or
                  use our Service. This includes when Users:
                </p>

                <ul>
                  <li>Visit Magnolia websites;</li>
                  <li>
                    Choose to interact with the community and forum pages on the
                    Services;
                  </li>
                  <li>
                    License and download third party asses from, or offer your
                    assets in, the Magnolia Asset Store;
                  </li>
                </ul>

                <p>
                  We receive information about Users, their devices, locations
                  and interactions with the Service primarily in two ways:
                </p>

                <ul>
                  <li>
                    Actively: When Users actively provide us with information.
                    For example, when a User creates an account with Unity, he
                    or she will type in a name, user name and email address and
                    may required to provide a date of birth or other
                    information.
                  </li>
                  <li>
                    Passively: When our systems automatically collect
                    information about browsers and devices and certain activity
                    within the Service.
                  </li>
                </ul>
              </div>
            </Row>

            {/* <Row>
              <h3></h3>

              <p>(This depends on which product or service is used)</p>
              <p>Information that you or others actively provide, including:</p>

              <ul>
                <li>Name</li>
                <li>Age or date of birth</li>
                <li>Gender</li>
                <li>Username</li>
                <li>Profile photo</li>
                <li>Email address</li>
                <li>Physical or mailing address</li>
                <li>Phone number</li>
                <li>
                  Name and contact information for a business or organisation
                </li>
                <li>
                  Password(s) that Users create to access parts of the Service
                </li>
              </ul> 
            </Row>*/}
            <Row>
              <h3>2. INFORMATION WE MAY COLLECT OR RECEIVE</h3>
              <div className="mt-4">
                <p>
                  (This depends on which product or service is used) Information
                  that you or others actively provide, including:
                </p>
                <p style={{ display: "none" }}>
                  There are many different ways your can use our services - to
                  search for and share information, to communicate with other
                  people or to create new content. When you share information
                  with us, for example by creating a
                </p>
                <ul>
                  <li>Name</li>
                  <li>Age or date of birth</li>
                  <li>Gender</li>
                  <li>Username</li>
                  <li>Profile photo</li>
                  <li>Email address</li>
                  <li>Physical or mailing address</li>
                  <li>Phone number</li>
                  <li>
                    Name and contact information for a business or organisation
                  </li>
                  <li>
                    Password(s) that Users create to access parts of the Service
                  </li>
                </ul>
              </div>
            </Row>
            <Row>
              <h3>PRIVACY POLICY</h3>
              <div className="mt-4">
                <p>
                  There are many different ways your can use our services - to
                  search for and share information, to communicate with other
                  people or to create new content. When you share information
                  with us, for example by creating a{" "}
                  <a href="https://Magnolia.com/legal/privacyPolicy.html#">
                    Magnolia Account
                  </a>
                  , we can make those services even better - to show you more
                  relevant search results and ads, to help you connect with
                  people or to make sharing with others quicker and easier. As
                  you use our services, we want you to be clear how we are using
                  information and the ways in which you can protect your
                  privacy.
                </p>
                <p>Our Privacy Policy explains:</p>

                <ul>
                  <li>What information we collect and why we collect it.</li>
                  <li>How we use that information.</li>
                  <li>
                    The choices we offer, including how to access and update
                    information.
                  </li>
                </ul>

                <p>
                  We have tried to keep it as simple as possible, but if you are
                  not familiar with terms like cookies, IP addresses, pixel tags
                  and browsers, then read about these key terms first. Your
                  privacy matter to Magnolia so whether you are new to Magnolia
                  or a long time user, please do take the time to get to know
                  our practices - and if you have any questions contact us.
                </p>
              </div>
            </Row>

            <Row>
              <h3>INFORMATION WE COLLECT </h3>
              <div className="mt-4">
                <p>
                  We collect information to provide better services to all of
                  our users - from figuring out basic stuff like which language
                  you speak, to more complex things like which ads you will find
                  most useful, the people who matter most to you online, or
                  which videos you might like.
                </p>
                <p>We collect information in the following ways:</p>

                <ol>
                  <li>
                    Information you give us. For example, many of our services
                    require you to sign up for a Magnolia Account. When you do,
                    we will ask for personal information, like your name, email
                    address, telephone number or credit card to store with your
                    account.
                    <del>
                      If you want to take full advantage of the sharing features
                      we offer, we might also ask you to create a publicly
                      visible Magnolia Profile, which may include your name and
                      photo
                    </del>
                  </li>
                  <li>
                    Information we get from your use of our services. We collect
                    information about the services that you use and how use
                    them, like when you watch a video, visit a website that uses
                    our advertising services, or view and interact with our ads
                    and content. This information includes:
                    <ol type="a">
                      <li>
                        Device information: We collect device specific
                        information (such as your hardware model, operating
                        system version, unique device identifiers, and mobile
                        network information including phone number). Magnolia
                        may associate your device identifiers or phone number
                        with your Magnolia Account.
                      </li>
                      <li>
                        Log information: When you use our services or view
                        content provided by Magnolia, we automatically collect
                        and store certain information in server logs. This
                        includes:
                        <ol type="i">
                          <li>
                            details of how you used our service, such as your
                            search queries.
                          </li>
                          <li>
                            telephony log information like your phone number,
                            calling-party number, forwarding numbers, time and
                            date of your request and referral URL.
                          </li>
                          <li>
                            Cookies that may uniquely identify your browser or
                            your Magnolia Account.
                          </li>
                        </ol>
                      </li>
                    </ol>
                  </li>
                  <li>
                    Location information: When you use Magnolia services, we may
                    collect and process information about your actual location.
                    We use various technologies to determine location, including
                    IP address, GPS, and other sensors that may, for example,
                    provide Magnolia with information on nearby devices, Wi-Fi
                    access points and cell towers.
                  </li>
                  <li>
                    Unique application numbers: Certain services include a
                    unique application number. This number and information about
                    your installation (for example, the operating system type
                    and application version number) may be sent to Magnolia when
                    you install or uninstall that service or when that service
                    periodically contacts our servers, such as for automatic
                    updates.
                  </li>
                  <li>
                    Local storage: We may collect and store information
                    (including personal information) locally on your device
                    using mechanisms such as browser web storage (including
                    HTML5) and application data caches.
                  </li>
                  <li>
                    Cookies and similar technologies: We and our partners use
                    various technologies to collect and store information when
                    you visit an Magnolia service, and this may include using
                    cookies or similar technologies to identify your browser or
                    device. We also use these technologies to collect and store
                    information when you interact with services we offer to our
                    partners, such as advertising services or Magnolia features
                    that may appear on other sites.
                  </li>
                </ol>

                <p>
                  Information we collect when you are signed in to Magnolia, in
                  addition to information we obtain about you from partners, may
                  be associated with Magnolia Account. When information is
                  associated with your Magnolia Account, we treat it as personal
                  information. For more information about how you can access,
                  manage or delete information that is associated with your
                  Magnolia Account, visit Transparency and choice section of
                  this policy.
                </p>
              </div>
            </Row>
          </Container>
        </div>

        <DarkFooter />
      </div>
    </>
  );
}

export default PrivacyPage;
