/*eslint-disable*/
import React from "react";

// reactstrap components
import { Container, Row, Col } from "reactstrap";
// core components

function OnePlace() {
  return (
    <>
      <div
        className="section text-center"
        style={{
          backgroundImage:
            "url(" + require("assets/img/one-bg.png").default + ")",
        }}
      >
        <Container>
          <div className="content-center brand">
            <h2 className="text-white py-3">
              Everything you need in one place from start to finish
            </h2>
            <Row>
              <Col>
                <a href="#" target="_blank" className="">
                  <img
                    alt="..."
                    className="one-logo pb-4"
                    src={require("assets/img/donate.png").default}
                  ></img>
                  <h3 className="text-white">Donate</h3>
                  <p className="text-white">accept and track your donations</p>
                </a>
              </Col>
              <Col>
                <a href="#" target="_blank">
                  <img
                    alt="..."
                    className="one-logo pb-4"
                    src={require("assets/img/thank.png").default}
                  ></img>
                  <h3 className="text-white">Thank</h3>
                  <p className="text-white">
                    automatically send donation receipts
                  </p>
                </a>
              </Col>
              <Col>
                <a href="#" target="_blank">
                  <img
                    alt="..."
                    className="one-logo pb-4"
                    src={require("assets/img/report.png").default}
                  ></img>
                  <h3 className="text-white">Report</h3>
                  <p className="text-white">Gain insight into your donor</p>
                </a>
              </Col>
              <Col>
                <a href="#" target="_blank">
                  <img
                    alt="..."
                    className="one-logo pb-4"
                    src={require("assets/img/engage.png").default}
                  ></img>
                  <h3 className="text-white">Engage</h3>
                  <p className="text-white">build donor relationships</p>
                </a>
              </Col>
              <Col>
                <a href="#" target="_blank">
                  <img
                    alt="..."
                    className="one-logo pb-4"
                    src={require("assets/img/steward.png").default}
                  ></img>
                  <h3 className="text-white">Steward</h3>
                  <p className="text-white p-0">increase donor retention</p>
                </a>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </>
  );
}

export default OnePlace;
