import React from "react";
import "./style.css";

// reactstrap components
import { Container, Row, Col } from "reactstrap";

// core components
import IndexNavbar from "components/Navbars/IndexNavbar.js";
import DarkFooter from "components/Footers/DarkFooter.js";

function CrowdCampaign() {
  const [firstFocus, setFirstFocus] = React.useState(false);
  const [lastFocus, setLastFocus] = React.useState(false);
  React.useEffect(() => {
    document.body.classList.add("landing-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("landing-page");
      document.body.classList.remove("sidebar-collapse");
    };
  }, []);
  return (
    <>
      <IndexNavbar />
      <div className="wrapper">
        <div className="section section_our_project">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12">
              <div className="our_project_category">
                <div className="project_cards_box">
                  <div className="project_card">
                    <div className="hover_color_bubble"></div>
                    <div className="so_top_icon pb-0">
                      <img
                        alt="..."
                        className="img img-responsive"
                        src={require("assets/img/value.png").default}
                      ></img>
                    </div>
                    <div className="solu_title">
                      <h3>Campaign 1</h3>
                    </div>
                    <div className="solu_description">
                      <p>
                        It is a long established fact that a reader will be
                        distracted by the readable content of a page when
                        looking at its layout.
                      </p>
                      <button type="button" className="read_more_btn">
                        Read More
                      </button>
                    </div>
                  </div>
                  <div className="project_card">
                    <div className="hover_color_bubble"></div>
                    <div className="so_top_icon">
                      <img
                        alt="..."
                        className="img img-responsive"
                        src={require("assets/img/value.png").default}
                      ></img>
                    </div>
                    <div className="solu_title">
                      <h3>Campaign 2</h3>
                    </div>
                    <div className="solu_description">
                      <p>
                        It is a long established fact that a reader will be
                        distracted by the readable content of a page when
                        looking at its layout.
                      </p>
                      <button type="button" className="read_more_btn">
                        Read More
                      </button>
                    </div>
                  </div>
                </div>
                <div className="project_cards_box sol_card_top_3">
                  <div className="project_card">
                    <div className="hover_color_bubble"></div>
                    <div className="so_top_icon">
                      <img
                        alt="..."
                        className="img img-responsive"
                        src={require("assets/img/value.png").default}
                      ></img>
                    </div>
                    <div className="solu_title">
                      <h3>Campaign 3</h3>
                    </div>
                    <div className="solu_description">
                      <p>
                        It is a long established fact that a reader will be
                        distracted by the readable content of a page when
                        looking at its layout.
                      </p>
                      <button type="button" className="read_more_btn">
                        Read More
                      </button>
                    </div>
                  </div>
                  <div className="project_card">
                    <div className="hover_color_bubble"></div>
                    <div className="so_top_icon">
                      <img
                        alt="..."
                        className="img img-responsive"
                        src={require("assets/img/value.png").default}
                      ></img>
                    </div>
                    <div className="solu_title">
                      <h3>Campaign 4</h3>
                    </div>
                    <div className="solu_description">
                      <p>
                        It is a long established fact that a reader will be
                        distracted by the readable content of a page when
                        looking at its layout.
                      </p>
                      <button type="button" className="read_more_btn">
                        Read More
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Container className="section">
          <Row>
            <Col md="4">
              <div className="profile-card-4 text-center">
                <img
                  alt="..."
                  className="img img-responsive"
                  src={require("assets/img/crowd/crowd-fund1.jpg").default}
                ></img>
                <div className="profile-content">
                  <div className="profile-name">Donation</div>
                  <div className="profile-description">
                    Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                    diam nonumy eirmod tempor.
                  </div>
                  <div className="row">
                    <div className="col">
                      <div className="profile-overview">
                        <p>Donate</p>
                        <h4>1300</h4>
                      </div>
                    </div>
                    <div className="col">
                      <div className="profile-overview">
                        <p>Raised</p>
                        <h4>250</h4>
                      </div>
                    </div>
                    <div className="col">
                      <div className="profile-overview">
                        <p>Target</p>
                        <h4>168</h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
            <Col md="4">
              <div className="profile-card-4 text-center">
                <img
                  alt="..."
                  className="img img-responsive"
                  src={require("assets/img/crowd/crowd-fund2.jpg").default}
                ></img>
                <div className="profile-content">
                  <div className="profile-name">Take Care</div>
                  <div className="profile-description">
                    Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                    diam nonumy eirmod tempor.
                  </div>
                  <div className="row">
                    <div className="col">
                      <div className="profile-overview">
                        <p>Donate</p>
                        <h4>1300</h4>
                      </div>
                    </div>
                    <div className="col">
                      <div className="profile-overview">
                        <p>Raised</p>
                        <h4>250</h4>
                      </div>
                    </div>
                    <div className="col">
                      <div className="profile-overview">
                        <p>Target</p>
                        <h4>168</h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
            <Col md="4">
              <div className="profile-card-4 text-center">
                <img
                  alt="..."
                  className="img img-responsive"
                  src={require("assets/img/crowd/crowd-fund3.jpg").default}
                ></img>
                <div className="profile-content">
                  <div className="profile-name">Free Food</div>
                  <div className="profile-description">
                    Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                    diam nonumy eirmod tempor.
                  </div>
                  <div className="row">
                    <div className="col">
                      <div className="profile-overview">
                        <p>Donate</p>
                        <h4>1300</h4>
                      </div>
                    </div>
                    <div className="col">
                      <div className="profile-overview">
                        <p>Raised</p>
                        <h4>250</h4>
                      </div>
                    </div>
                    <div className="col">
                      <div className="profile-overview">
                        <p>Target</p>
                        <h4>168</h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
        <DarkFooter />
      </div>
    </>
  );
}

export default CrowdCampaign;
